import React from "react"
import Layout from "../components/layout"
import SeO from "../components/SEO"
import Readings from "../components/readings"
import YesOrNo from "../components/yesOrNo"
import Logo from "../components/logo"




const Home = () => {

   


  return (
    <Layout>
    
      <SeO title="Home" />
       

   <Logo />
  
    <YesOrNo />
    <Readings />
    
    </Layout>
  )
}

export default Home

