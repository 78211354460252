import "bootstrap/dist/css/bootstrap.min.css"
import { createGlobalStyle } from "styled-components"


const GlobalStyles = createGlobalStyle` 

:root {
  --purple: #412456;
  --gold: rgba(251, 201, 38, 0.75);
  --yellow: rgb(245,223,77);
  --green: #31ab1f;
  --white: #fefefa;
  --black: #070707;
  --shade: rgba(65, 36, 86, 0.35);
  --shaded: rgba(65, 36, 86, 0.5);
}

html {
  background-color: #412456;
  background-blend-mode: luminosity;
  font-size: 62.5%;
  margin-top: 0;
  padding-top: 0;

  
}

body {
  background: transparent;
  margin: 0;
  padding: 0;
  color: var(--gold);
  a {
    color: var(--white);
    &:hover {
      color: var(--gold);
    }
  }
}

h1, h2, h3, h4, h5, h6 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.25rem;
  font-family: "aviano-flare", sans-serif;
  font-weight: 700;
  font-style: normal;
  text-rendering: optimizeLegibility;
  letter-spacing: 0.15rem;
  text-shadow: 1px 1px 1px var(--black)
}

h1 {
  font-size: 2.4rem;
}

h2 {
  font-size: 2.2rem;
}

h3  {
  font-size: 2.0rem;
  }

h4 {
  font-size: 1.8rem;
}  

p, a {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 0.85rem;
  font-family: "futura-pt", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-rendering: optimizeLegibility;
  font-size: 1.6rem;
  line-height: 1.1;
}

.bg {
  min-width: 100vw;
  min-height: 100vh;
}

.base-container {
  min-width: 100vw;
  min-height: 100vh;
  display: flex;

}

.border-container {
  background: var(--shaded);
  width: 95%;
  min-height: 95vh;
  margin: 1rem auto;
  border-radius: 1rem;
  border: none;
  padding: 0;
  box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-container {
  background: var(--shaded);
  width: 95%;
  min-height: 90vh;
  margin: 1rem auto;
  border-radius: 1rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  h3 {
    text-align: center;
  }
}

.readings-container {
  width: 95%;
  background: var(--shaded);
  border-radius: 1rem;
  margin: 1rem auto;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.8);
  p {
  width: 90%;
  text-align: center;
  margin-top: 1rem;
  }
}

.readings-button {
  width: 75%;
  margin: 1rem auto;
  padding: 1rem;
  border-radius: 1rem;
  @media (min-width: 576px) {
    width: 60%;
  }
  
}

.btn {
  border: thin solid var(--gold);
  background: var(--shaded);
  color: var(--gold);
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5);
  &:hover {
    background: var(--green);
    border: thin solid var(--purple);
  }
  a {
    text-transform: uppercase;
    text-shadow: 0.1rem 0.1rem rgba(0, 0, 0, 0.6);
    letter-spacing: 0.1rem;
    text-decoration: none;
    color: var(--gold);
    &:hover {
      color: var(--white)
    }
  }
}

.yes-or-no-container {
  width: 95%;
  background: var(--shaded);
  margin: 1rem auto;
  padding: 1rem 0;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.8);
  p {
    width: 90%;
    text-align: center;
    margin-top: 1rem;
  }
  h4 {
    padding-top: 1rem;
    text-align: center;
  }
}

.video-play {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
}

.video-play video {
  min-width: 100%;
  min-height: 100%;
 width: auto;
 height: auto;
  position: absolute;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translateY(-50%, -50%);
}



`

export default GlobalStyles