import React from "react"
import Container from "react-bootstrap/Container"
import Button from "react-bootstrap/Button"

export default function YesOrNo() {
  return (
    <Container className="yes-or-no-container">
      
      
      <p>Got a burning question that just needs a yes or no answer? </p>
      <Button className="readings-button" type="button">
        <a href="https://answers.askthetarot.com.au">
          Ask The Tarot Yes Or No
        </a>
      </Button>
    </Container>
  )
}