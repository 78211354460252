import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"

const StyledLogo = styled.div` 
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.8);
  @media (min-width: 576px) {
    width: 15rem;
    height: 15rem;
  }
`

export default function Logo() {

  const data = useStaticQuery(graphql` {
    placeholderImage: file(relativePath: {eq: "att.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 150
          layout: CONSTRAINED
          placeholder: BLURRED
          formats: WEBP
        )
      }
    }
  }
  `)

  if (!data?.placeholderImage?.childImageSharp?.gatsbyImageData) {
    return <div>Ask The Tarot Logo</div>
  }
  return(
    <StyledLogo>
      <GatsbyImage image={data.placeholderImage.childImageSharp.gatsbyImageData}  alt="Ask The Tarot Logo" />
    </StyledLogo>
  ); 
}