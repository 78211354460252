import React from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';

export default function Readings() {
  return (
    <Container className='readings-container'>
      <p>
        Looking for a traditional reading that delves deeper into the tarot's
        wisdom to provide more detailed answers?
      </p>
      <Button className='readings-button' type='button'>
        <a href='https://readings.askthetarot.com.au'>
          Personalised Tarot & Chakra Readings
        </a>
      </Button>
    </Container>
  );
}
